@import 'src/style/base.scss';

.container {
  max-width: $container-max-width;
  padding: 0 $container-padding-sm;
  margin: 0 auto;

  @include md {
    padding: 0 $container-padding;
  }
}


