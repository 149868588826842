@import 'src/style/base.scss';

.divider {
  width: 100%;
  height: 1px;
  box-sizing: content-box;
  padding: 0 $container-padding-sm;
  margin-top: 2rem;
  margin-left: calc(#{$container-padding-sm} * -1);
  background-color: $medium-gray;

  @include md {
    box-sizing: border-box;
    padding: 0;
    margin-top: 4rem;
    margin-right: 0;
    margin-left: 0;
  }
}
