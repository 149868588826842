@import 'src/style/base.scss';

.grid {
  position: relative;
  display: grid;
  min-height: 100%;
  grid-auto-rows: minmax(11.875rem, auto);
  grid-column-gap: 3rem;
  grid-row-gap: 3rem;
  grid-template-columns: repeat(1, 1fr);

  @include md {
    grid-template-columns: repeat(2, 1fr);
  }

  @include lg {
    grid-template-columns: repeat(3, 1fr);
  }

}

.grid > div {
  animation: fade-in 0.3s ease-in forwards;
  opacity: 0;

  @for $i from 0 through 10 {
    &:nth-child(#{$i}) {
      animation-delay: $i * 0.15s;
    }
  }
}

.image {
  width: 100%;
  height: 400px;
  object-fit: contain;
}

.canvasWrapper canvas {
  width: 351px !important;
  height: 454px !important;
  // width: 375px !important;
  margin: -30px auto 0;
  // transform-origin: top left;

  @include md {
    width: 494px !important;
    height: 639px !important;
  }

  @include lg {
    width: 992px !important;
    height: 1283px !important;
  }
}

.buttonContainer {
  display: flex;
  width: 100%;
  justify-content:center;
}

.button {
  display: flex;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  border: 1px solid #000;
  border-radius: 50%;
  box-shadow: $box-shadow;
  transform: rotate(90deg);

  &:last-child {
    margin-left: 10px;
    transform: rotate(270deg)
  }

  &:hover {
    opacity: 0.8;
  }
}

.disabled {
  border-color: #1010104d;
  cursor: default;
}

:global(.react-pdf__Page__textContent),
:global(.react-pdf__Page__annotations) {
  display: none;
}

