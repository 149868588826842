
@mixin numbers {
  font-size: 7rem;
  font-weight: 500;
  letter-spacing: -0.188rem;
  line-height: 8.5rem;

  @include md {
    font-size: 8.5rem;
    letter-spacing: -0.228rem;
    line-height: 9.2rem;
  }

  @include lg {
    font-size: 11.2rem;
    letter-spacing: -0.3rem;
    line-height: 11.2rem;
  }
}

@mixin characters {
  font-size: 3.4rem;
  font-weight: 500;
  letter-spacing: -0.091rem;
  line-height: 3.4rem;

  @include md {
    font-size: 4.1rem;
    letter-spacing: -0.11rem;
  }

  @include lg {
    font-size: 5.6rem;
    letter-spacing: -0.15rem;
  }
}

@mixin careersHeroText {
  font-size: 18.5rem;
  font-weight: bold;
  letter-spacing: -0.671rem;
  line-height: 22rem;

  @include md {
    font-size: 36.4rem;
    letter-spacing: -1.3rem;
    line-height: 43.4rem;
  }

  @include lg {
    font-size: 56rem;
    letter-spacing: -2rem;
    line-height: 66.8rem;
  }
}

@mixin header1 {
  font-size: 2.9rem;
  font-weight: 500;
  letter-spacing: -0.1rem;
  line-height: 3.3rem;

  @include md {
    font-size: 4rem;
    line-height: 4.1rem;
  }

  @include lg {
    font-size: 5.6rem;
    letter-spacing: -0.2rem;
    line-height: 5.6rem;
  }
}

@mixin header2 {
  font-size: 2.4rem;
  font-weight: 500;
  letter-spacing: -0.025rem;
  line-height: 2.4rem;

  @include md {
    font-size: 2.7rem;
    letter-spacing: -0.062rem;
    line-height: 3.4rem;
  }

  @include lg {
    font-size: 3rem;
    letter-spacing: -0.075rem;
    line-height: 3.4rem;
  }
}

@mixin header3 {
  font-size: 1.8rem;
  font-weight: 500;
  letter-spacing: -0.05rem;
  line-height: 2.4rem;

  @include md {
    font-size: 2.1rem;
    line-height: 2.8rem;
  }
}

@mixin header4 {
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: -0.025rem;
  line-height: 2.4rem;

  @include md {
    line-height: 2.8rem;
  }
}

@mixin list {
  font-size: 1.6rem;
  font-weight: normal;
  letter-spacing: -0.025rem;
  line-height: 2.6rem;
}

@mixin body {
  font-size: 1.6rem;
  font-weight: normal;
  letter-spacing: -0.025rem;
  line-height: 2.4rem;

  @include md {
    line-height: 2.8rem;
  }
}

@mixin body-leadership {
  font-size: 1.6rem;
  font-weight: normal;
  letter-spacing: -0.025rem;
  line-height: 2.3rem;
}

@mixin body-serif-sm {
  font-size: 2rem;
  font-weight: normal;
  letter-spacing: -0.025rem;
  line-height: 3rem;

  @include md {
    line-height: 3.4rem;
  }
}

@mixin body-serif-md {
  font-size: 2.2rem;
  font-weight: normal;
  letter-spacing: -0.025rem;
  line-height: 3.2rem;

  @include lg {
    font-size: 2.4rem;
    line-height: 3.6rem;
  }
}

@mixin body-serif-lg {
  font-size: 3rem;
  font-weight: normal;
  letter-spacing: -0.05rem;
  line-height: 3.8rem;

  @include md {
    font-size: 3.2rem;
    line-height: 4rem;
  }

  @include lg {
    font-size: 3.6rem;
    line-height: 4.4rem;
  }
}

@mixin body-serif-xl {
  font-size: 3.2rem;
  font-weight: normal;
  letter-spacing: -0.05rem;
  line-height: 3.8rem;

  @include md {
    font-size: 4.4rem;
    letter-spacing: -0.147rem;
    line-height: 5rem;
  }

  @include lg {
    font-size: 3.6rem;
    letter-spacing: -0.05rem;
    line-height: 4.4rem;
  }
}

@mixin quote {
  font-size: 2.3rem;
  font-weight: normal;
  letter-spacing: -0.024rem;
  line-height: 3rem;

  @include md {
    font-size: 2.4rem;
    letter-spacing: -0.025rem;
    line-height: 3.2rem;
  }

  @include lg {
    font-size: 3.6rem;
    letter-spacing: -0.05rem;
    line-height: 4.4rem;
  }

  > p {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
    line-height: inherit;
  }
}

@mixin footer-title {
  font-size: 1.4rem;
  font-weight: 800;
  letter-spacing: -0.033rem;
  line-height: 2.8rem;

  @include md {
    font-size: 1.4rem;
    letter-spacing: -0.038rem;
  }

  @include lg {
    font-size: 1.6rem;
    letter-spacing: -0.05rem;
  }
}

@mixin footer-text {
  color: $black;
  font-size: 1.3rem;
  font-weight: normal;
  letter-spacing: -0.016rem;
  line-height: 2.0rem;

  @include md {
    font-size: 1.3rem;
    letter-spacing: -0.018rem;
    line-height: 2.4rem;
  }

  @include lg {
    font-size: 1.4rem;
    letter-spacing: -0.02rem;
  }
}

@mixin default-page-top-padding {
  padding-top: 17.8rem;

  @include md {
    padding-top: 22rem;
  }

  @include lg {
    padding-top: 26rem;
  }
}

@mixin default-page-bottom-padding {
  padding-bottom: 12.8rem;

  @include md {
    padding-bottom: 16rem;
  }

  @include lg {
    padding-bottom: 20rem;
  }
}


@mixin on-enter {
  .no-touchevents &:hover {
    @content;
  }
}
