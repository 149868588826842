@import 'src/style/base.scss';

.wrapper {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.container {
  position: sticky;
  z-index: 100;
  top: 0;
  border-bottom: 1px solid $black;
  background-color: $white;
  box-shadow: $box-shadow;
}

.innerWrapper {
  display: flex;
  width: 100%;
  max-width: $container-max-width;
  flex-shrink: 0;
  align-items: flex-end;
  justify-content: space-between;
}

.logo {
  width: 7rem;
  padding: 1rem 0;
}

.hamburger {
  margin-bottom: 15px;

  @include lg {
    display: none;
  }
}
