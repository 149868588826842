@import 'src/style/base.scss';

.subtitle {
  @include header3;
  margin-bottom: 3rem;
}

.link {
  display: inline-block;
  margin-bottom: 1rem;
  opacity: 1;
  transition: opacity $duration-1;

  &:hover {
    opacity: 0.85;
    text-decoration: underline;
  }
}
