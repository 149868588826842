@import 'src/style/base.scss';

.sponsorWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 3rem 0;
  opacity: 0;
  transform: translateY(30%);
  transition: transform 1s, opacity 1s;
}

.link {
  display: flex;
  flex-basis: 50%;
  flex-shrink: 0;
  justify-content: center;

  opacity: 1;
  transition: opacity $duration-1;

  &:hover {
    opacity: 0.8;
  }

  &:not(:last-child) {
    margin-bottom: 3rem;
  }

  @include md {
    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 4rem;
}

.sponsorsContainer {
  display: flex;
  width: 100%;
  flex-basis: 100%;
  flex-direction: column;
  align-items: center;

  @include md {
    flex-direction: row;
    padding: 2rem;
  }
}

.sponsors {
  display: block;
  width: auto;
  width: 100%;
  height: auto;
  max-height:20rem;

  flex-shrink: 0;

  @include md {
    &:last-child {
      height: 8rem;
      object-fit: contain;
    }
  }

  @include lg {
    &:last-child {
      height: 12rem;
      object-fit: contain;
    }
  }

}

.scienceFond {
  width: 100%;

  @include md {
    max-width: 30rem;
  }
}

.fadeIn {
  opacity: 1;
  transform: translateY(0);
}

.text {
  padding-top: 1rem;
  text-align: center;
}
