@import 'src/style/base.scss';

.imageWrapper {
  overflow: hidden;
  width: 100%;
  height: 20rem;
}

.image {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  backface-visibility: hidden;
  object-fit: cover;
  opacity: 1;
  perspective: 0;
  transition: transform $duration-1, opacity $duration-1;

}

.text {
  @include header4;
  color: $dark-gray;
  font-weight: bold;
}

.date {
  margin-top: 0.5rem;
  color: $medium-gray;
}

.card:hover .image {
  opacity: 0.9;
  transform: scale(1.1);
}
