@import 'src/style/base.scss';
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lightgallery.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lg-zoom.css');

.ratio {
  position: relative;
  display: inline-block;
  width: 100%;
  flex-shrink: 0;
  align-items: center;

  &::after {
    display: block;
    height: auto;
    padding-bottom: 100%;
    content: '';
  }

  @include md {
    width: 50%;
  }

  @include lg {
    width: 20%;
  }
}

.galleryItem {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 10px;
  box-shadow: $box-shadow;
  cursor: pointer;
  object-fit: cover;
}

.grid  a {
  animation: fade-in 0.3s ease-in forwards;
  opacity: 0;

  @for $i from 0 through 10 {
    &:nth-child(#{$i}) {
      animation-delay: $i * 0.15s;
    }
  }
}
