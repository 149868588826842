@import 'src/style/base.scss';

.link {
  color: $blue;
  opacity: 1;
  transition: opacity $duration-2;
  word-wrap: break-word;

  &:hover {
    opacity: 0.8;
  }
}
