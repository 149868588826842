@import 'src/style/base.scss';

.bioWrapper {
  display: flex;
  flex-basis: 33.33%;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;

  &:hover {
    .bioImage {
      opacity: 0.8;
    }

    .bioName {
      color: $medium-gray;
    }
  }


  &:nth-child(3n) {
    align-items: flex-end;
  }

  &:nth-child(3n + 1) {
    align-items: flex-start;
  }

  &:last-child:hover {
    .bioImage {
      opacity: 1;
    }

    .bioName {
      color: black;
    }
  }
}

.pi {
  display: flex;
  flex-direction: column;
}

.bioContent {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
}

.bioOuterWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bioOuterWrapper > .bioWrapper {
  margin-bottom: 2rem;
}

.bioImage {
  width: 22.5rem;
  height: 22.5rem;
  border-radius: 2000px;
  object-fit: cover;
  opacity: 1;
  transition: opacity $duration-2;

}

.bioName {
  display: flex;
  width: 22.5rem;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
  text-align: center;
  transition: color $duration-2;
  @include header3;
}

.bioInnerWrapper {
  display: flex;
  width: 80%;
  flex-wrap: wrap;
  justify-content:center;
  row-gap: 2rem;
}
