@import 'src/style/base.scss';

.logoWrapper {
  display: flex;
  width: 100%;
  justify-content: center;
}

.logo {
  width: 100%;
  margin: 0 auto;

  @include md {
    width: 80%;
  }

  @include lg {
    width: 60%;
  }
}

.title {
  margin: 3rem auto 4rem;
  color: $red;
  text-align: center;
  @include header2;

  @include md {
    // width: 85%;
    margin: 4rem auto;
    text-align: center;
  }

  @include lg {
    width: 85%;
    margin: 6rem auto 4rem;
  }
}

.image {
  width: 100%;
}

.cardsWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4rem 0;

  @include lg {
    flex-direction: row;
  }
}
