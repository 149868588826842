@import 'src/style/base.scss';

.text {
  @include body;
}

.subtitle {
  @include header2;
  margin: 3rem 0;
  color: $text-red;
  opacity: 0;
  transform: translateY(100%);
  transition: transform 1s, opacity 1s;
}

.intro {
  margin-bottom: 2rem;
  color: $blue-text;
  font-weight: bold;
  @include header3
}

.list {
  margin: 1rem 0;
  color: $dark-gray;
  font-weight: bold;
}

.header {
  @include body;
  margin: 0 auto;
}

.imageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include footer-text;
  margin-top: 1rem;
}

.img {
  width: 100%;
  width: 100%;
  padding: 2rem 0 0.5rem;
}

.impact {
  width: 100%;
  width: 100%;
}

.implementation {
  width: 100%;
  width: 80%;
  padding: 1rem 0 0.5rem;

  @include md {
    padding: 2rem 0 0.5rem;
  }
}

.tableWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 3rem;

  @include lg {
    margin-top: 6rem;
  }
}

.rowWrapper {
  display: flex;
  width: 100%;
  flex-shrink: 0;
  color: $dark-gray;
  @include header4;

  @include md {
    @include header3;
  }

  @include lg {
    width: 80%;
    @include header3;
  }

  & * {
    display: flex;
    min-height: 4rem;
    align-items: center;
    justify-content: center;
    text-align: center;
    @include footer-text;

    @include md {
      @include body;
    }
  }

  &:first-child * {
    height: auto;
    padding: 0.5rem 0;
    background-color: $light-gray;
    color: $text-red;
    font-weight: bold;

    .title {
      border: none !important;
    }
  }

  &:last-child {
    border-bottom: 3px solid $light-gray;
  }
}

.id {
  flex-basis: 15%;
}

.title {
  flex-basis: 60%;
  border-right: 3px solid $light-gray;
  border-left: 3px solid $light-gray;

  @include md {
    padding: 0.5rem 0;
  }
}

.coordinator {
  flex-basis: 35%;
}

.fadeIn {
  opacity: 1;
  transform: translateY(0);
}
