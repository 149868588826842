@mixin sm-only() {
  @media (max-width: calc(#{$breakpoint-md} - 1)) {
    @content;
  }
}

@mixin md() {
  @media (min-width: $breakpoint-md) {
    @content;
  }
}

@mixin lg() {
  @media (min-width: $breakpoint-lg) {
    @content;
  }
}
