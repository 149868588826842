@import 'src/style/base.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 1.8rem;
  line-height: 2.4rem;


  @include lg {
    flex-direction: row;
  }
}

.footer {
  position: relative;
  padding: 4rem 0 5rem;
  background-color: $medium-gray;
  color: $white;

  @include lg {
    padding: 4rem 0;
  }
}

.icon {
  width: 2.4rem;
  height: 2.4rem;
  flex-shrink: 0;
  margin-right: 1rem;
}

.iconLink {
  opacity: 1;
  transition: opacity $duration-1;

  &:hover {
    opacity: 0.8;
  }
}

.innerWrapper {
  flex-basis: 31%;

  &:not(:last-child) {
    margin-bottom: 3rem;
  }
}

.socialTextWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.socialIcons {
  display: flex;
  width: 100%;
  align-items: center;
  padding-left: 3.4rem;

  .iconLink:not(:first-child) {
    margin-left: 1.5rem;
  }
}

.contactWrapper {
  display: flex;
  flex-direction: column;
  padding-left: 3.4rem;
}

.logo {
  @include footer-text;
  position: absolute;
  bottom: 1rem;
  color: $white;
}
