html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Rabelo', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
}

body {
  min-width: 100wh;
  // Default body text styles TODO add body color variable
  color: black;

  @include body();
}

*:focus:not(:focus-visible),
*:focus:not(:focus-visible) * {
  outline: none !important;
}

a {
  color: inherit;
  text-decoration: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

table {
  border-collapse: collapse;
}

// reset button styles
button {
  display: inline-block;
  padding: 0;
  border: none;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: none;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
}

// reset input styles
input {
  padding: 0;
  border: none;
  background: none;
  outline: none;
}
