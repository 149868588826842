@import 'src/style/base.scss';

.errorHandler {
  padding-top: 40px;

  @include md {
    padding-top: 60px;
  }

  @include lg {
    padding-top: 80px;
  }
}

.errorHandlerTitle {
  @include header2;
}

.errorHandlerText {
  @include body;
}

