@import 'src/style/base.scss';

.wrapper {
  display: none;

  @include lg {
    display: flex;
    align-items: flex-end;
  }
}

.tab {
  padding: 0.5rem 1rem;
  border: 1px solid $black;
  border-bottom: none;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  transition: all $duration-1;

  &:hover {
    background-color: $medium-gray;
  }
}

.tabActive {
  background-color: $medium-gray;
  color: $white;

  &:hover {
    background-color: $medium-gray;
  }
}

